<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="isAvailable" @change="loadPlantPointCards(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="true">可用</el-radio-button>
                <el-radio-button :label="false">不可用</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantPointCards(true)" />
                </div>
            </div>
            <el-table :data="plantPointCards.data" class="mt-2">
                <el-table-column prop="farmerName" label="种植户" sortable />
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column prop="totalAmount" label="总额度" sortable />
                <el-table-column prop="availableAmount" label="可用额度" sortable />
                <el-table-column prop="validityBeginDate" label="有效起始日期" sortable :formatter="$moment.sdFormatter"
                    width="150" />
                <el-table-column prop="validityEndDate" label="有效截止日期" sortable :formatter="$moment.sdFormatter"
                    width="150" />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showPlantPointCardDetailDialog(scope.row)">明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantPointCards.totalRecords"
                :current-page="plantPointCardsPagination.currentPage" :page-size="plantPointCardsPagination.pageSize"
                class="mt-3" @current-change="plantPointCardsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="plantPointCardDetailDialogVisible" :title="plantPointCard.farmerName+' 明细'"
            :close-on-click-modal="false" width="800px">
            <el-table :data="plantPointCardDetails">
                <el-table-column prop="typeName" label="类型" width="80" />
                <el-table-column prop="amount" label="金额" sortable width="120" />
                <el-table-column prop="dateTime" label="日期" sortable :formatter="$moment.sdtFormatter" width="150" />
                <el-table-column prop="remark" label="备注" sortable />
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isAvailable: true,
                key: null,
                plantPointCards: {},
                plantPointCardsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                plantPointCard: {},
                plantPointCardDetails: [],
                plantPointCardDetailDialogVisible: false,
            };
        },
        methods: {
            async loadPlantPointCards(resetPage = false) {
                if (resetPage) {
                    this.plantPointCardsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantPointCard/GetPlantPointCards', {
                    params: {
                        companyId: this.$store.state.companyId,
                        isAvailable: this.isAvailable,
                        key: this.key,
                        pageIndex: this.plantPointCardsPagination.currentPage - 1,
                        pageSize: this.plantPointCardsPagination.pageSize,
                    }
                });
                this.plantPointCards = response.data;
            },
            plantPointCardsPaginationCurrentChange(page) {
                this.plantPointCardsPagination.currentPage = page;
                this.loadPlantPointCards();
            },
            showPlantPointCardDetailDialog(plantPointCard) {
                this.plantPointCard = JSON.parse(JSON.stringify(plantPointCard));
                this.plantPointCardDetailDialogVisible = true;
                this.loadPlantPointCardDetails();
            },
            async loadPlantPointCardDetails() {
                let response = await this.$axios.get('/api/PlantPointCard/GetPlantPointCardDetails', { params: { plantPointCardId: this.plantPointCard.id } });
                this.plantPointCardDetails = response.data;
            },
        },
        created() {
            this.loadPlantPointCards();
        },
    };
</script>